import React, { useEffect, useState } from "react";
import { LocalDate, Month } from "@js-joda/core";
import { useTranslation } from "react-i18next";
import { TimeReportDto } from "model/TimeReport";
import { Color } from "config/colors";
import { FontFamily, FontSize } from "config/font";
import { WorkerTimeReports } from "./workerTimeReports.component";
import { TimeReportModal } from "./timeReportModal.component";
import {
  MonthSelectContainer,
  ArrowSign,
  WorkerTimeReportContainer,
  NoTimeReportsWrapper,
  CircularProgressWrapper,
  RowSpaceBetween,
  Row,
  TotalCostWrapper,
} from "./monthlyOverview.styled";
import { Text, H4 } from "components/Typography/text.styled";
import { RatingModal } from "./ratingModal.component";
import { EndorseModal } from "./endorsements/endorseModal.component";
import { Api } from "services/api/api.service";
import { useCompanyStore } from "web-apps/company/stores/companyStore/companyStore";
import { CircularProgress } from "@material-ui/core";
import { useTimeReportStore } from "web-apps/company/stores/timeReportStore/timeReportStore";
import { exportAllToCSV, exportToCSV } from "utils/utils";
import { CustomIcon } from "components/icon/customIcon.component";
import { TotalTimeDetails } from "./totalTimeDetails.component";
import { Link } from "react-router-dom";

export const MonthlyOverview: React.FC = () => {
  const { t } = useTranslation();
  const [chosenMonth, setChosenMonth] = useState<Month>(
    LocalDate.now().month()
  );

  const [currentDate, setCurrentDate] = useState<LocalDate>(LocalDate.now());

  const [chosenTimeReportWithWorker, setChosenTimeReportWithWorker] =
    useState<TimeReportDto | null>(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [ratingModalOpen, setRatingModalOpen] = useState(false);
  const [endorseModalOpen, setEndorseModalOpen] = useState(false);
  const [totalCostMonthly, setTotalCostMonthly] = useState(0);
  const [totalCostMonthlyApproved, setTotalCostMonthlyApproved] = useState(0);
  const [companyState] = useCompanyStore();
  const [isLoading, setIsLoading] = useState(true);
  const [timeReportState, , timeReportDispatch] = useTimeReportStore();

  useEffect(() => {
    setChosenMonth(currentDate.month());
  }, [currentDate]);

  const GetMonthlyWorkersWithTimeReports = async () => {
    setIsLoading(true);
    const companyId = companyState.company?.id;
    if (companyId) {
      let monthlyList: any[] = [];

      try {
        const res =
          await Api().company.timeReport.getAllTimeReportWorkersMonthly(
            companyId,
            currentDate.year(),
            currentDate.monthValue()
          );

        const promises = res.data.map(async (worker) => {
          const timeReportRes =
            await Api().company.timeReport.getAllWorkerTimeReportsMonthly(
              companyId,
              worker.workerId,
              currentDate.year(),
              currentDate.monthValue()
            );
          return {
            worker,
            timeReports: timeReportRes.data,
          };
        });

        const result = await Promise.all(promises);
        monthlyList = result.filter(Boolean);
        setIsLoading(false);
        timeReportDispatch({
          type: "UPDATE_MONTHLY_TIME_REPORTS",
          payload: monthlyList,
        });
      } catch (err) {
        console.log(err, "ERROR FETCHING MONTHLY TIME REPORTS", err);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    GetMonthlyWorkersWithTimeReports();
  }, [currentDate, companyState.company?.id]);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <TotalTimeDetails
          month={currentDate.monthValue()}
          chosenMonth={chosenMonth}
          year={currentDate.year()}
        />
        <MonthSelectContainer>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.minusMonths(1));
            }}
          >
            &#60;
          </ArrowSign>
          <Text
            fontSize={FontSize.H4}
            fontFamily={FontFamily.MontserratSemiBold}
            style={{ margin: 0 }}
          >
            {t(`CompanyTimeReport.${chosenMonth}`) + " " + currentDate.year()}
          </Text>
          <ArrowSign
            onClick={() => {
              setCurrentDate((prevState: any) => prevState.plusMonths(1));
            }}
          >
            &#62;
          </ArrowSign>
        </MonthSelectContainer>
        <TotalCostWrapper>
          <Text color={Color.White}>
            {t("CompanyTimeReport.TotalCostAll")}:{" "}
            {parseFloat(totalCostMonthly.toFixed(2))} kr
          </Text>
          <Text color={Color.White} fontSize={FontSize.Small}>
            {t("CompanyTimeReport.TotalCostApproved")}:{" "}
            {parseFloat(totalCostMonthlyApproved.toFixed(2))} kr
          </Text>
        </TotalCostWrapper>
      </div>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Row
          onClick={async () => {
            if (companyState.company?.companyName) {
              setIsLoading(true);
              await exportAllToCSV(
                timeReportState.monthlyTimeReports,
                `${chosenMonth}_Time reports`,
                t,
                companyState.company.id
              );
              setIsLoading(false);
            }
          }}
        >
          <Text
            fontFamily={FontFamily.MontserratSemiBold}
            color={Color.MidnightBlue}
            fontSize={FontSize.Small}
          >
            {t("CompanyTimeReport.ExportCSVForAll")}
          </Text>
          <CustomIcon name="download" size="25px" color={Color.MidnightBlue} />
        </Row>
      </div>
      {!isLoading &&
        timeReportState.monthlyTimeReports.map((timeReportData, index) => {
          return (
            <WorkerTimeReportContainer key={index}>
              <RowSpaceBetween>
                <Link to={`workers/${timeReportData.worker.workerId}`}>
                  <H4>
                    {`${timeReportData.worker.firstName} ${timeReportData.worker.lastName}`}
                    <CustomIcon
                      name="person"
                      size="20px"
                      color={Color.MidnightBlue}
                    />
                  </H4>
                </Link>
                <Row
                  onClick={async () => {
                    if (companyState.company?.companyName) {
                      setIsLoading(true);
                      await exportToCSV(
                        timeReportData,
                        `${timeReportData.worker.firstName} ${
                          timeReportData.worker.lastName
                        }_${chosenMonth}${currentDate.year()}_${
                          companyState.company.companyName
                        }`,
                        companyState.company.id,
                        t
                      );
                      setIsLoading(false);
                    }
                  }}
                >
                  <Text
                    fontFamily={FontFamily.MontserratSemiBold}
                    color={Color.MidnightBlue}
                    fontSize={FontSize.Small}
                  >
                    {t("CompanyTimeReport.ExportCSV")}
                  </Text>
                  <CustomIcon
                    name="download"
                    size="25px"
                    color={Color.MidnightBlue}
                  />
                </Row>
              </RowSpaceBetween>
              <WorkerTimeReports
                monthlyWorkerWithTimeReports={timeReportData}
                setChosenTimeReportWithWorker={setChosenTimeReportWithWorker}
                setModalOpen={setModalOpen}
                setTotalCostMonthly={setTotalCostMonthly}
                setTotalCostMonthlyApproved={setTotalCostMonthlyApproved}
                totalCostMonthly={totalCostMonthly}
                showCheckInCheckOutColumns
                month={currentDate.monthValue()}
                year={currentDate.year()}
              />
            </WorkerTimeReportContainer>
          );
        })}
      {isLoading && (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}
      {timeReportState.monthlyTimeReports.length === 0 && !isLoading && (
        <NoTimeReportsWrapper>
          <H4>{t("CompanyTimeReport.NoTimeReports")}</H4>
          <Text>{t("CompanyTimeReport.NoTimeReportsForThisMonth")}</Text>
        </NoTimeReportsWrapper>
      )}
      {chosenTimeReportWithWorker && (
        <TimeReportModal
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setRatingModalOpen={setRatingModalOpen}
        />
      )}
      {ratingModalOpen && chosenTimeReportWithWorker && (
        <RatingModal
          ratingModalOpen={ratingModalOpen}
          setRatingModalOpen={setRatingModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
          setEndorseModalOpen={setEndorseModalOpen}
        />
      )}
      {endorseModalOpen && chosenTimeReportWithWorker && (
        <EndorseModal
          endorseModalOpen={endorseModalOpen}
          setEndorseModalOpen={setEndorseModalOpen}
          chosenTimeReportWithWorker={chosenTimeReportWithWorker}
        />
      )}
    </>
  );
};
