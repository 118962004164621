import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontFamily, FontSize } from "config/font";
import { Color } from "config/colors";

import { H3, Text } from "components/Typography/text.styled";
import { CustomIcon } from "components/icon/customIcon.component";
import {
  ButtonContainer,
  CalendarGrid,
  CalendarGridContainer,
  CalendarHeaderGrid,
  GigHeader,
  ModalBody,
  TitleRow,
  WeekDay,
  CalendarViewText,
  ViewContainer,
  CircularProgressWrapper,
  Row,
} from "./weeklyCalendar.styled";
import moment from "moment";
import { ButtonStyled } from "components/buttons/buttons.styled";
import { CompanyGigDto } from "model/Gig";
import { ShiftDto } from "model/Shift";
import { ApplicationDto } from "model/Application";
import { TimeReportDto } from "model/TimeReport";
import { CalendarGig } from "./calendarGig.component";
import { CircularProgress, Modal } from "@material-ui/core";
import { SaveGigForm } from "../saveGig/saveGigForm/saveGigForm.component";
import { useCompanyStore } from "../../stores/companyStore/companyStore";
import { Api } from "services/api/api.service";
import { useCalendarStore } from "web-apps/company/stores/calendarStore/calendarStore";
import { addLeadingZero } from "utils/utils";
import { CalendarTemplatePicker } from "./template/calendarTemplatePicker.component";
import { DayShiftsModal } from "./dayShiftsModal/dayShiftsModal.component";

export type ChosenTimeReportWithWorker = TimeReportDto & {
  requestedRate: number | undefined;
  duration: string;
  worker: {
    id: number;
    firstName?: string | null;
    lastName?: string | null;
  };
};

export type WeekData = {
  gig: CompanyGigDto;
  applications: ApplicationDto[];
  timeReports: TimeReportDto[];
  shifts: ShiftDto[];
};

type CurrentDate = {
  date: moment.Moment;
  weekDay: string;
};

export const WeeklyCalendarComponent: React.FC = () => {
  const [companyState] = useCompanyStore();
  const [calendarState, , calendarDispatch] = useCalendarStore();

  const { t } = useTranslation();
  const weekdayList = [
    t("WeekDays.MONDAYSHORT"),
    t("WeekDays.TUESDAYSHORT"),
    t("WeekDays.WEDNESDAYSHORT"),
    t("WeekDays.THURSDAYSHORT"),
    t("WeekDays.FRIDAYSHORT"),
    t("WeekDays.SATURDAYSHORT"),
    t("WeekDays.SUNDAYSHORT"),
  ];
  const today = moment();
  const todayWeekNumber = moment().week();
  const [currentDate, setCurrentDate] = useState(
    localStorage.getItem("CalendarDate")
      ? moment(localStorage.getItem("CalendarDate"))
      : today
  );
  const todayWeekday = currentDate.day();
  const [weekNumber, setWeekNumber] = useState(currentDate.week());
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(
    moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
  );
  const [currentDates, setCurrentDates] = useState<CurrentDate[]>([]);
  const [showCreateGigModal, setShowCreateGigModal] = useState(false);
  const [clickedDay, setClickedDay] = useState("");
  const [view, setView] = useState<"compact" | "full">("full");
  const [isLoading, setIsLoading] = useState(false);
  const [showDayShiftsModal, setShowDayShiftsModal] = useState(false);

  useEffect(() => {
    fetchCalendarGigs();
  }, [currentDates]);

  const createDateNumbers = () => {
    const firstDate = currentDates[0].date;
    const lastDate = currentDates[6].date;

    const start = `${firstDate.year()}${addLeadingZero(
      firstDate.month() + 1
    )}${addLeadingZero(firstDate.date())}`;
    const end = `${lastDate.year()}${addLeadingZero(
      lastDate.month() + 1
    )}${addLeadingZero(lastDate.date())}`;
    return { start: parseInt(start), end: parseInt(end) };
  };

  const fetchCalendarGigs = () => {
    if (companyState.company?.id && currentDates.length > 0) {
      setIsLoading(true);
      Api()
        .company.calendar.getCalendarGigs(
          companyState.company.id,
          createDateNumbers().start,
          createDateNumbers().end
        )
        .then((res) => {
          setIsLoading(false);
          calendarDispatch({
            type: "UPDATE_WEEKLY_CALENDAR",
            payload: res.data,
          });
        })
        .catch((err) => {
          setIsLoading(false);

          console.log("ERROR FETCHING CALENDAR GIGS", err);
        });
    }
  };

  const handleClickDay = (day: CurrentDate) => {
    setClickedDay(day.date.format("YYYY-MM-DD"));
    setShowDayShiftsModal(true);
  };

  const handleClickCreateGig = () => {
    const today = moment().set({ hour: 0, minutes: 0, seconds: 0 });
    if (currentDates[0].date >= today) {
      setClickedDay(currentDates[0].date.format("YYYY-MM-DD"));
      setShowCreateGigModal(true);
    } else {
      setClickedDay(moment(today).format("YYYY-MM-DD"));
      setShowCreateGigModal(true);
    }
  };

  useEffect(() => {
    localStorage.setItem(
      "CalendarDate",
      moment(currentDate).format("YYYY-MM-DD")
    );
    setWeekNumber(currentDate.week());
    setFirstDayOfWeek(
      moment(moment(currentDate).subtract(todayWeekday - 1, "days"))
    );
  }, [currentDate]);

  useEffect(() => {
    let dates: CurrentDate[] = [];
    for (let x = 0; x < 7; x++) {
      dates.push({
        date: moment(moment(firstDayOfWeek).add(x, "days")),
        weekDay: weekdayList[x],
      });
    }
    setCurrentDates(dates);
  }, [firstDayOfWeek]);

  const resetStateVariables = () => {
    setCurrentDates([]);
  };
  const clickPrevWeek = () => {
    setCurrentDate(moment(currentDate.subtract(7, "days")));
    resetStateVariables();
  };

  const clickNextWeek = () => {
    setCurrentDate(moment(currentDate.add(7, "days")));
    resetStateVariables();
  };

  const clickToday = () => {
    setCurrentDate(moment(today));
  };

  return (
    <>
      <TitleRow>
        <ButtonContainer>
          <ButtonStyled
            color={Color.White}
            backgroundColor={Color.MidnightBlue}
            onClick={handleClickCreateGig}
          >
            {t("CalendarCompany.Create")}
          </ButtonStyled>
        </ButtonContainer>
        <CustomIcon
          name="chevron-left"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickPrevWeek();
          }}
        />
        <Text
          fontSize={FontSize.H4}
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratSemiBold}
        >{`${t("CalendarCompany.Week")} ${weekNumber}`}</Text>

        <Text
          fontSize={FontSize.Standard}
          color={Color.MidnightBlue}
          fontFamily={FontFamily.MontserratRegular}
        >{`${moment(firstDayOfWeek).format("DD MMM")} - ${moment(
          moment(firstDayOfWeek).add(6, "days")
        ).format("DD MMM")}   ${moment(firstDayOfWeek).format("YYYY")} ${
          moment(moment(firstDayOfWeek).add(6, "days")).format("YYYY") !==
          moment(firstDayOfWeek).format("YYYY")
            ? "/" + moment(firstDayOfWeek).add(6, "days").format("YYYY")
            : ""
        }`}</Text>
        <CustomIcon
          name="chevron-right"
          size="50px"
          color={Color.MidnightBlue}
          padding="10px"
          onClick={() => {
            clickNextWeek();
          }}
        />
        <ButtonContainer>
          <ButtonStyled
            color={Color.White}
            backgroundColor={Color.MidnightBlue}
            disabled={
              todayWeekNumber === weekNumber &&
              today.format("YYYY") === currentDate.format("YYYY")
                ? true
                : false
            }
            onClick={clickToday}
          >
            {t("CalendarCompany.Today")}
          </ButtonStyled>
        </ButtonContainer>
      </TitleRow>
      <ViewContainer>
        <Row>
          <CalendarViewText
            active={view === "full"}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            onClick={() => setView("full")}
          >
            {t("CalendarCompany.FullView")}
          </CalendarViewText>
          <CalendarViewText
            active={view === "compact"}
            fontFamily={FontFamily.MontserratSemiBold}
            fontSize={FontSize.Standard}
            onClick={() => setView("compact")}
          >
            {t("CalendarCompany.CompactView")}
          </CalendarViewText>
        </Row>
        <CalendarTemplatePicker
          startDate={firstDayOfWeek.toDate()}
          refetchCalendarGigs={fetchCalendarGigs}
          currentDates={currentDates}
        />
      </ViewContainer>
      <CalendarHeaderGrid>
        <GigHeader>
          
        </GigHeader>
        {currentDates.map((date: CurrentDate) => (
          <WeekDay
            key={date.weekDay}
            future={
              date.date >= moment().set({ hour: 0, minutes: 0, seconds: 0 })
                ? true
                : false
            }
            today={
              date.date.format("DD-MM-YYYY") === today.format("DD-MM-YYYY")
                ? true
                : false
            }
            onClick={() => handleClickDay(date)}
          >
            <Text
              fontSize={FontSize.Standard}
              color={Color.White}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {date.weekDay}
            </Text>
            <Text fontSize={FontSize.Small} color={Color.White}>
              {moment(date.date).format("D/M")}
            </Text>
          </WeekDay>
        ))}
      </CalendarHeaderGrid>
      {!isLoading ? (
        <CalendarGridContainer>
          <CalendarGrid>
            {calendarState.currentWeek.map((gigData, gigDataIndex: number) => {
              return (
                <CalendarGig
                  key={gigData.id}
                  gigData={gigData}
                  gigDataIndex={gigDataIndex}
                  view={view}
                />
              );
            })}
          </CalendarGrid>
          {calendarState.currentWeek.length === 0 && (
            <Text
              style={{ textAlign: "center" }}
              fontSize={FontSize.Standard}
              color={Color.SeaBlue600}
              fontFamily={FontFamily.MontserratSemiBold}
            >
              {t("CalendarCompany.NoShiftsThisWeek")}
            </Text>
          )}
        </CalendarGridContainer>
      ) : (
        <CircularProgressWrapper>
          <CircularProgress />
        </CircularProgressWrapper>
      )}

      <Modal
        open={showCreateGigModal}
        onClose={() => {
          setShowCreateGigModal(false);
        }}
      >
        <ModalBody>
          <H3>{t("CalendarCompany.CreateGig")}</H3>
          <SaveGigForm
            shiftDate={clickedDay}
            setShowCreateGigModal={setShowCreateGigModal}
            fromCalendar={true}
          />
        </ModalBody>
      </Modal>
      {showDayShiftsModal && (
        <DayShiftsModal
          setModalOpen={setShowDayShiftsModal}
          modalOpen={showDayShiftsModal}
          day={clickedDay}
        />
      )}
    </>
  );
};
